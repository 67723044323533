import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Container } from '../container'
import styles from '../delivery/delivery.module.css'
import mapImgMob from './NP_web_assets/map_mob.png'
import mapImg from './NP_web_assets/map_desk.png'

export const DeliverySection = ({ id }) => {
  const { t } = useTranslation()
  const isEn = document.location.pathname.includes('/en')

  return (
    <section id={id && id} className={styles.delivery}>
      <Container isWide={true}>
        <h3 className="fancyUnderlineText sectionTitleNew text-center">
          Nejpřesnější a nejflexibilnější doprava po Plzni zdarma!
        </h3>
        <div className={styles.deliveryContent}>
          <div className={styles.deliveryImages}>
            <img
              src={mapImg}
              className={styles.deliveryImageDesktop}
              alt="car picture"
            />
            <img
              src={mapImgMob}
              className={styles.deliveryImageMobile}
              alt="map picture"
            />
          </div>
          <div className={styles.deliveryListWrap}>
            <div>
              <h3 className={styles.deliveryListTitle}>
                {t('home.delivery.listTitle')}
              </h3>
              <ul className={styles.deliveryList}>
                <li>
                  V zelené zóně <strong>za dovoz neplatíš.</strong>
                </li>
                <li>
                  Vybereš si jedno ze{' '}
                  <strong>3 časových oken pro doručení,</strong> už žádné
                  celovečerní nahánění s kurýry!
                </li>
                <li>
                  Změnily se ti plány? Místo si můžeš flexibilně změnit ještě v
                  den dovozu do 12:00.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}
